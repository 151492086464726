<template>
  <div>
    <div class="text-center">
      <h2 class="text-2xl font-bold text-primary">{{ catalog.name }}</h2>
      <p class="text-sm">{{ catalog.description }}</p>
    </div>

    <LoadingVue class="mx-auto text-primary h-20" v-if="fetching" />

    <div class="grid sm:grid-cols-6 grid-cols-4 gap-1 mt-1" v-if="!fetching">
      <ListItem
        class="bg-white text-center"
        v-for="(item, index) in records"
        :key="index"
        :item="item"
        :hasCaption="false"
      />

      <nuxt-link
        :to="localePath('/order')"
        class="block bg-white rounded overflow-hidden"
      >
        <div class="aspect-square bg-main flex justify-center items-center">
          <span class="text-white font-bold text-2xl">More</span>
        </div>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import ListItem from "@/components/product/ListItem.vue";
import LoadingVue from "@luminexs/components/icons/Loading.vue";
import { mapActions, mapGetters } from "vuex";
import { find } from "lodash";

export default {
  components: {
    ListItem,
    LoadingVue,
  },
  props: {
    catalog: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
    };
  },
  methods: {
    ...mapActions({
      setRecommend: "app/setRecommend",
    }),
    fetch() {
      this.fetching = true;

      this.$axios
        .get(`/api/catalogs/${this.catalog.id}/recommend`)
        .then(({ data }) => {
          this.setRecommend({ no: this.catalog.id, items: data.data });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.fetching = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      recommend: "app/recommend",
    }),
    records() {
      return find(this.recommend, { no: this.catalog.id })?.items || [];
    },
  },
  created() {
    if (!this.records.length) this.fetch();
  },
};
</script>

<style lang="scss" scoped></style>
