<template>
  <app-view :title="app" :backable="false" :footerShow="true">
    <!-- 海布 -->
    <swiper
      :banners="banners"
      :loop="false"
      size="aspect-video"
      swiperClass="w-full h-full overflow-hidden"
      imageClass="w-full h-full object-contain"
      class="bg-white"
    />

    <!-- 主導覽 -->
    <div class="grid grid-cols-4 gap-1 mt-1">
      <div
        class="text-chicago tracking-wider text-center py-4 bg-white"
        v-for="item in catalogs"
        :key="item.name"
      >
        <nuxt-link class="mb-1 h-10 block" :to="switched(item)">
          <div class="flex flex-col justify-center items-center">
            <img
              :src="item.image || '/imgs/no_photo.jpg'"
              :alt="item.name"
              class="w-8 h-8 mb-1"
              v-lazy-load
              loading="lazy"
            />
            <div class="font-medium text-center text-xs">{{ item.name }}</div>
          </div>
        </nuxt-link>
      </div>
    </div>

    <PromotionVue
      v-for="catalog in catalogs"
      :key="catalog.no"
      class="mt-12 mx-1"
      :catalog="catalog"
    />
  </app-view>
</template>

<script>
import { iMoney, iSnack, iElectronic, iGame } from "@/components/icons";
import { mapActions, mapGetters } from "vuex";
import Swiper from "@luminexs/components/image/Swiper.vue";
import PromotionVue from "@/components/product/Promotion.vue";

export default {
  components: {
    iMoney,
    iSnack,
    iElectronic,
    iGame,
    Swiper,
    PromotionVue,
  },
  head() {
    const info = this.$store.getters["app/info"];

    return {
      title: info.name,
      meta: [
        {
          hid: "canonical",
          rel: "canonical",
          href: `${process.env.APP_URL}`,
        },
        {
          hid: "keywords",
          name: "keywords",
          content: info.keywords,
        },
        {
          hid: "description",
          name: "description",
          content: info.description,
        },
        {
          hid: "og:title",
          name: "og:title",
          property: "og:title",
          content: info.name,
        },
        {
          hid: "og:site_name",
          name: "og:site_name",
          property: "og:site_name",
          content: info.name,
        },
        {
          hid: "og:url",
          name: "og:url",
          property: "og:url",
          content: `${process.env.APP_URL}`,
        },
        {
          hid: "og:description",
          name: "og:description",
          property: "og:description",
          content: info.description,
        },
        {
          hid: "og:image",
          name: "og:image",
          property: "og:image",
          content: `${process.env.APP_URL}/static/icon.png`,
        },
        // canonical
        {
          hid: "canonical",
          rel: "canonical",
          href: `${process.env.APP_URL}`,
        },
      ],
    };
  },

  auth: false,

  async asyncData({ store }) {
    return {
      app: store.getters["app/info"].name,
    };
  },

  methods: {
    

    switched(category) {
      const path = this.localePath({
        name: "order",
        query: { category: category.no, title: category.name },
      });
      
      return path;
    },

    

    mycatalogs() {
      return this.catalogs.filter((item) => item.no != 0);
    },
  },

  computed: {
    ...mapGetters({
      info: "app/info",
      banners: "app/banners",
      catalogs: "app/catalogs",
      recommend: "app/recommend",
    }),
  },
  
};
</script>
